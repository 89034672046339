<template>
  <div class="face-selector">
    <div class="face-selector__top">
      <!-- <button
        class="button button--transparent button--shadow face-selector__button face-selector__button--manual"
        @click.prevent="decrease()"
      >&ndash;</button> -->
      <input
        class="face-selector__field"
        v-model="currentFace"
        @blur="checkFace()"
        maxlength="6"
      >
      <!-- <button class="button button--transparent button--shadow face-selector__button face-selector__button--manual" @click.prevent="increase()">+</button> -->
    </div>
  </div>
</template>

<style scoped>
.face-selector {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.face-selector__top {
  display: flex;
  justify-content: center;
  align-items: stretch;
}

.face-selector__field {
  text-align: center;
  font-size: 20px;
  height: 46px;
  width: 120px;
  margin: 0 10px;
  border-radius: 4px;
  background-color: #f8f8f8;
  border: none;
  transition: background-color .2s;
}

.face-selector--detail .face-selector__field {
  background-color: #fff;
}

.face-selector__field:hover {
  background-color: #f0f0f0;
}

.face-selector__field:focus {
  outline: none;
  background-color: #e8e8e8;
}

.face-selector__button {
  padding: 4px 10px;
  border-radius: 4px;
}

.face-selector__button.face-selector__button--manual {
  font-size: 24px;
  min-width: 46px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .09),
              0 3px 1px -2px rgba(0, 0, 0, .12),
              0 1px 5px 0 rgba(0, 0, 0, .06);
}

.face-selector__bottom {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.face-selector__button.face-selector__button--face {
  font-weight: 600;
  font-size: 16px;
  padding: 0;
  margin: 0 2px 4px;
  height: 40px;
  width: 100px;
}

.face-selector__button.face-selector__button--face span {
  padding: 4px 10px;
}

.face-selector__button.face-selector__button--face span + span {
  padding: 4px 12px;
  background: #fcfcfc;
  text-align: left;
  align-self: stretch;
  display: flex;
  align-items: center;
}

@media (max-width: 639px) {
  .face-selector__button.face-selector__button--face span + span {
    padding: 4px 7px;
  }

  .face-selector {
    margin-bottom: 15px;
  }
}

.face-selector__button.face-selector__button--face small {
  line-height: 1.2;
}

@media (max-width: 639px) {
  .face-selector__button.face-selector__button--face small {
    line-height: 1.1;
    font-size: 68%;
    letter-spacing: -.5px;
  }
}

.face-selector__button.face-selector__button--with-desc {
  width: 100%;
  overflow: hidden;
}

.face-selector__button.face-selector__button--with-desc span {
  padding: 4px 0;
  width: 80px;
}

.face-selector__button.face-selector__button--with-desc span + span {
  flex: 1 0 auto;
}
.face-selector__range {
  margin-top: 7px;
}
</style>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import utils from 'base/modules/utils'

export default {
  props: [
    'product',
    'value'
  ],
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters({
      pointsExchangeRate: 'main/pointsExchangeRate',
    }),
    currentFace: {
      set (currentFace) {
        const multiplier = this.product.meta.points_multiplier || 1
        this.$emit('input', {
          face: currentFace,
          points: Math.ceil(currentFace * multiplier * this.pointsExchangeRate)
        })
      },
      get () {
        return this.value && this.value.face
      }
    },
    step () {
      return this.product.meta.flexible.step
    },
    full () {
      const full = []
      for (let i = this.min; i <= this.max; i += this.step) {
        full.push(i)
      }
      return full
    },
    min () {
      return this.product.meta.flexible.min
    },
    max () {
      return this.product.meta.flexible.max
    }
  },
  methods: {
    decrease () {
      const currentIndex = this.full.indexOf(this.currentFace)
      if (currentIndex > 0) {
        this.currentFace = this.full[currentIndex - 1]
      }
    },
    increase () {
      const currentIndex = this.full.indexOf(this.currentFace)
      if (currentIndex + 1 < this.full.length) {
        this.currentFace = this.full[currentIndex + 1]
      }
    },
    setFace (face) {
      this.currentFace = face
    },
    checkFace () {
      this.currentFace = this.closest(this.full, this.currentFace)
    },
    closest (array, num) {
      return utils.closest(array, num)
    }
  },
  mounted () {
    if (!this.currentFace) {
      // this.setFace(this.min)
      Vue.nextTick()
    }
  }
}
</script>
