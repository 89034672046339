<template>
  <div>
    <div class="alert alert--danger" v-if="error" v-html="error"></div>
    <div class="exchange-form">
      <div class="exchange-form__loading" v-if="loading">
        Пожалуйста, подождите...
      </div>
      <slot name="pre-text"></slot>
      <div class="row">
        <div class="col-md-4 col-lg-3" v-if="$store.state.catalog.categories.length > 1">
          <div class="exchange-form__categories-list categories-list" v-if="!loading && $store.state.catalog.categories.length > 1">
            <div class="categories-list__select">
              <select class="categories-list__select-control" v-model="currentCategory">
                <option value="0" class="categories-list__toggle">Показать все</option>
                <option :value="category.id" v-for="(category, index) in $store.state.catalog.categories" class="categories-list__toggle" :class="{ 'categories-list__toggle_active' : category.id == $store.state.catalog.currentCategory }" :key="index">
                  {{ category.title }}
                </option>
              </select>
            </div>
            <div class="categories-list__container">
              <a href="#" v-on:click.prevent="$store.dispatch('catalog/setCurrentCategory', 0)" class="categories-list__toggle">
                Показать все
              </a>
              <a href="#" v-for="(category, index) in $store.state.catalog.categories" v-on:click.prevent="$store.dispatch('catalog/setCurrentCategory', category.id)" class="categories-list__toggle" :class="{ 'categories-list__toggle_active' : category.id == $store.state.catalog.currentCategory }" :key="index">
                {{ category.title }}
              </a>
            </div>
          </div>
        </div>
        <div class="col-12" :class="{ 'col-md-8 col-lg-9': $store.state.catalog.categories.length > 1 }">
          <div class="exchange-form__products-list products-list" v-if="!loading && filtered.length > 0">
            <div class="row">
              <div v-for="product in filtered" class="col-sm-6 col-md-6 col-lg-4" :data-name="product.title" :data-id="product.id" :key="product.id">
                <div class="products-list__item product">

                  <div class="product__image">
                    <img :srcset="product.main_image.small+', '+product.main_image.medium+' 2x'" :src="product.main_image.medium" :alt="product.title">
                  </div>

                  <div class="product__controls" v-on:click.prevent.stop="showModal('exchange-'+product.id, product)">
                    <span class="product__control product__control--show-exchange">Получить</span>
                  </div>

                  <div class="modal micromodal-slide" :id="'exchange-'+product.id" aria-hidden="true">
                    <div class="modal__overlay" tabindex="-1" data-micromodal-close>
                      <div class="modal__container" role="dialog" aria-modal="true" aria-labelledby="modal-1-title">
                        <div class="modal__wrapper">
                          <header class="modal__header">
                            <h2 class="modal__title" :id="'exchange-'+product.id+'-title'" v-html="product.title_detail"></h2>
                            <button class="modal__close" aria-label="Close modal" data-micromodal-close></button>
                          </header>
                          <main class="modal__content" :id="'exchange-'+product.id+'-content'">
                            <div class="product__exchange-image">
                              <img :src="product.main_image.large" alt="">
                            </div>

                            <slot name="product-pre-text" :product="product"></slot>

                            <div class="product__disclaimer">
                              <div class="product__disclaimer-notice" v-if="product.accepted === false">Перед выбором призов внимательно ознакомьтесь с условиями использования сертификатов.</div>
                              <div class="product__disclaimer-heading">
                                <h3>Условия использования</h3>
                                <button class="button button--rounded" v-if="product.disclaimerVisible === false" @click="product.disclaimerVisible = true">Показать</button>
                              </div>
                              <div class="product__disclaimer-text" v-if="product.disclaimerVisible === true" v-html="product.disclaimer"></div>
                              <div class="product__disclaimer-buttons" v-if="product.accepted === true && product.disclaimerVisible === true">
                                <button class="button button--rounded" @click="product.disclaimerVisible = false">Скрыть</button>
                              </div>
                              <div class="product__disclaimer-buttons" v-if="product.accepted === false">
                                <button class="button button--large button--rounded" @click="product.accepted = true; product.disclaimerVisible = false;">Принять</button>
                                <button class="button button--transparent button--large button--rounded" @click="closeModal('exchange-'+product.id)">Отказаться</button>
                              </div>
                            </div>
                            <div class="product__faces-container" v-if="product.accepted === true">
                              <div class="product__faces-select">
                                <div class="faces-select" v-if="hasFlexible(product)">
                                    <h4>
                                      Укажите номинал
                                      <small v-if="hasFlexible(product)" class="faces-select__range-desc">
                                        от <b>{{ product.meta.flexible.min }} {{ currency }}</b> до <b>{{ product.meta.flexible.max }} {{ currency }}</b> (шаг <b>{{ product.meta.flexible.step }} {{ currency }}</b>)
                                      </small>
                                    </h4>
                                    <face-selector
                                      v-model="price"
                                      :product="product"
                                    />
                                </div>
                                <div v-else class="faces-select">
                                  <h4>
                                    Выберите номинал
                                    <small v-if="hasFlexible(product)" class="faces-select__range-desc">
                                      от <b>{{ product.meta.flexible.min }} {{ currency }}</b> до <b>{{ product.meta.flexible.max }} {{ currency }}</b> (шаг <b>{{ product.meta.flexible.step }} {{ currency }}</b>)
                                    </small>
                                  </h4>
                                  <select
                                    v-if="!hasFlexible(product)"
                                    class="form-field form-field--large form-field--select"
                                    v-model="price"
                                    required
                                  >
                                    <option v-for="(price, index) in product.meta.pricelist" :value="price" :key="index">
                                      {{ price.face ? price.face : price.points }} {{ currency }} &ndash; {{ price.points }} баллов {{ price.alt ? '(' + price.alt + ')' : ''}}
                                    </option>
                                  </select>
                                </div>
                                <button class="button button--large button--rounded" @click="addToCart(product.id, price)">В корзину</button>
                              </div>
                            </div>
                          </main>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div class="exchange-form__products-list products-list" v-if="!loading && filtered.length === 0">К сожалению, каталог пуст.</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import catalog from '../api/catalog'
import MicroModal from 'micromodal'
import appError from 'base/modules/app-error'
import FaceSelector from './FaceSelector'
import { mapGetters } from 'vuex'
import { currency } from 'base/modules/constants'

const storeModule = {
  namespaced: true,
  state: {
    currentCategory: 0,
    products: [],
    categories: []
  },
  mutations: {
    updateCurrentCategory: function (state, payload) {
      state.currentCategory = payload
    },
    updateProducts: function (state, payload) {
      state.products = payload
    },
    updateCategories: function (state, payload) {
      state.categories = payload
    }
  },
  actions: {
    setCurrentCategory ({ commit }, payload) {
      commit('updateCurrentCategory', payload)
    },
    setProducts ({ commit }, payload) {
      commit('updateProducts', payload)
    },
    setCategories ({ commit }, payload) {
      commit('updateCategories', payload)
    }
  }
}

export default {
  name: 'Catalog',
  components: {
    FaceSelector
  },
  data () {
    return {
      price: null,

      loading: true,
      error: ''
    }
  },
  computed: {
    ...mapGetters({
      region: 'main/region',
      pointsExchangeRate: 'main/pointsExchangeRate',
    }),
    currency () {
      return currency[this.region]
    },
    filtered () {
      if (this.$store.state.catalog.products.length > 0) {
        return this.$store.state.catalog.products.filter(product => {
          return (typeof product.meta.pricelist !== 'undefined' && product.meta.pricelist !== null && product.meta.pricelist.length > 0 && (this.$store.state.catalog.currentCategory !== 0 ? product.categories.indexOf(this.$store.state.catalog.currentCategory) !== -1 : true))
        })
      } else {
        return []
      }
    },
    currentCategory: {
      get () {
        return this.$store.state.catalog.currentCategory
      },
      set (value) {
        this.$store.dispatch('catalog/setCurrentCategory', parseInt(value))
      }
    }
  },
  methods: {
    getCatalog: function () {
      return catalog
    },
    getHashValue: function (key) {
      const matches = location.hash.match(new RegExp(key + '=([^&]*)'))
      return matches ? matches[1] : null
    },
    showModal: function (modalId, product) {
      this.price = product.meta.pricelist[0]
      MicroModal.show(modalId, {
        onShow: modal => {
          document.body.style.position = 'fixed'
          document.body.style.width = '100%'
        },
        onClose: modal => {
          document.body.style.position = 'initial'
          document.body.style.width = 'auto'
        },
        openTrigger: 'data-micromodal-open',
        closeTrigger: 'data-micromodal-close',
        disableScroll: true,
        disableFocus: false,
        awaitCloseAnimation: false,
        debugMode: true
      })
    },
    closeModal: function (modalId) {
      MicroModal.close(modalId)
    },
    addToCart: function (productId, price) {
      this.beforeAddToCart(productId, price).then(() => {
        this.$store.dispatch('cart/add', {
          id: productId,
          qty: 1,
          price: price
        })
        this.afterAddToCart(productId, price)
      }).catch(function () {
        Vue.$logger.error('addToCart | ошибка')
      })
      MicroModal.close()
    },
    beforeAddToCart: function () {
      Vue.$logger.debug('Catalog | Before add to cart')
      return new Promise((resolve, reject) => {
        resolve()
      })
    },
    afterAddToCart: function () {
      Vue.$logger.debug('Catalog | After add to cart')
      return new Promise((resolve, reject) => {
        resolve()
      })
    },
    afterMounted: function () {
      this.$store.dispatch('catalog/setCurrentCategory', parseInt(this.getHashValue('category_id')) > 0 ? parseInt(this.getHashValue('category_id')) : 0)

      this.getCatalog().get(this).then(catalog => {
        this.loading = false

        const products = this.afterGetProducts(catalog.products.map(item => {
          item.accepted = false
          item.disclaimerVisible = true
          return item
        }))

        this.$store.dispatch('catalog/setProducts', products)
        this.$store.dispatch('catalog/setCategories', catalog.categories)
      }).catch((error) => {
        this.loading = false

        this.error = appError.getMessage(error.message)

        Vue.$logger.debug('Catalog | catalog.get | ошибка: ', error)
      })
    },
    hasFlexible (product) {
      return product.meta.flexible
    },
    afterGetProducts (products) {
      Vue.$logger.debug('Catalog | After getting products')
      return products
    }
  },
  created: function () {
    if (typeof this.$store._modules.root._children.catalog === 'undefined') {
      this.$store.registerModule('catalog', storeModule)
    }
  },
  mounted: function () {
    this.afterMounted()
  }
}
</script>

<style lang="css" scoped>
  .exchange-form {
    padding: 0 0 20px;
  }

  .categories-list {
    margin-bottom: 20px;
  }

  .categories-list__select {
    height: 38px;
    line-height: 36px;
    border-radius: var(--border-radius);
    border: 1px solid #ccc;
    color: #111;
    font-size: 15px;
    display: block;
    margin-bottom: 9px;
    outline: none;
    position: relative;
  }

  @media (min-width: 768px) {
    .categories-list__select {
      display: none;
    }
  }

  .categories-list__select:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 10px;
    margin-top: -4px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7px 7px 0 7px;
    border-color: var(--main-color) transparent transparent;
  }

  .categories-list__select-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    background: none;
    font-family: inherit;
    padding: 0 8px;
    width: 100%;
    height: 100%;
    display: block;
    cursor: pointer;
    position: relative;
    z-index: 1;
    font-size: 16px;
  }

  .categories-list__select-control:focus {
    outline: none;
  }

  .categories-list__select-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }

  .categories-list__container {
    display: none;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: -5px;
    margin-right: -5px;
  }

  @media (min-width: 768px) {
    .categories-list__container {
      /* display: -webkit-flex;
      display: -ms-flex;
      display: flex; */
      display: block;
    }
  }

  .categories-list__toggle {
    border-radius: var(--border-radius);
    color: #111;
    font-size: 16px;
    text-decoration: none;
    white-space: nowrap;
    display: block;
    position: relative;
    padding-left: 16px;
  }

  .categories-list__toggle + .categories-list__toggle {
    margin-top: 10px;
  }

  .categories-list__toggle::before {
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 3px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background: #ccc;
  }

  @media (min-width: 768px) {
    .categories-list__toggle {
      font-size: 15px;
    }
  }

  .categories-list__toggle:hover {
    background-color: #eee;
    background-color: var(--white-color);
  }

  .categories-list__toggle_active {
    color: #333;
    color: var(--main-color);
  }

  .categories-list__toggle_active::before {
    background: var(--main-color);
  }

  .product {
    position: relative;
    margin-bottom: 22px;
  }

  .product:hover .product__controls,
  .product:focus .product__controls {
    opacity: 1;
  }

  .product__image {
    position: relative;
    z-index: 1;
    padding-top: 45.59%;
    height: 0;
    overflow: hidden;
    border-radius: var(--border-radius);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .product__image img {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  .product__controls {
    background: rgba(0,0,0,0.5);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;
    text-align: center;
    opacity: 0;
    transition: 0.3s opacity;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: var(--border-radius);
  }

  .product__control {
    display: inline-block;
    text-decoration: none;
    color: #fff;
  }

  .product__control:focus {
    outline: none;
  }

  .product__control--show-exchange {
    background: var(--main-color);
    padding: 10px 16px;
    font-size: 20px;
    margin: 0 0 6px;
    border-radius: var(--border-radius);
    transition: opacity 0.3s;
  }

  .product__hidden-popups {
    display: none;
  }

  .product__exchange {
    font-size: 14px;
    line-height: 20px;
    color: #333;
    padding: 0 10px;
    max-width: 620px;
  }

  .product__exchange h4 {
    font-size: 20px;
    line-height: 26px;
    color: #666;
    margin: 0 0 10px;
  }

  .product__exchange-image {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    max-width: 620px;
    margin: 0 auto 20px;
    border-radius: var(--border-radius);
  }

  .product__exchange-image img {
    display: block;
    width: 100%;
  }

  .product__disclaimer {
    background: #f0f0f0;
    padding: 14px;
    margin-bottom: 14px;
    border-radius: var(--border-radius);
  }

  .product__disclaimer-notice {
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 10px;
    margin-bottom: 10px;
    color: #f00;
    font-size: 15px;
  }

  .product__disclaimer-heading {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  @media (min-width: 480px) {
    .product__disclaimer-heading {
      flex-direction: row;
    }
  }

  .product__disclaimer-heading h3 {
    font-size: 20px;
    line-height: 26px;
    margin: 0 0 10px;
    color: var(--main-color);
  }

  @media (min-width: 480px) {
    .product__disclaimer-heading h3 {
      margin: 0;
    }
  }

  .product__disclaimer-text {
    max-height: 300px;
    background: #fff;
    padding: 10px;
    font-size: 14px;
    color: #333;
    overflow-y: auto;
    margin-top: 12px;
    margin-bottom: 12px;
    border-radius: var(--border-radius);
  }

  .product__disclaimer-buttons {
    display: flex;
    justify-content: end;
    gap: 10px;
  }

  .product__faces {
    display: block;
    margin: 0 -5px;
  }

  .product__faces a {
    display: block;
    padding: 10px 20px;
    flex-grow: 2;
    flex-shrink: 2;
    font-size: 16px;
    margin: 0 0 6px;
    text-decoration: none;
    border-radius: var(--border-radius);
  }

  @media (min-width: 480px) {
    .product__faces {
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .product__faces a {
      display: inline-block;
      margin: 0 5px 10px;
    }
  }

  .product__faces-select {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    align-items: center;
  }

  .product__faces-select button {
    white-space: nowrap;
  }

  .faces-select {
    display: flex;
    align-items: center;
  }

  .faces-select h4 {
    margin-bottom: 0;
    margin-right: 30px;
  }

  .faces-select__range-desc {
    font-weight: 400;
    display: block;
  }

  @media (max-width: 640px) {
    .product__faces-select {
      flex-direction: column;
    }

    .form-field--select {
      margin-bottom: 15px;
    }

    .faces-select {
      flex-direction: column;
      align-items: stretch;
      text-align: center;
    }

    .faces-select h4 {
      margin-right: 0;
      margin-bottom: 15px;
    }
  }

  .product__faces a small {
    font-size: 12px;
  }
</style>
